import React, { useEffect } from 'react'
import {
    Box,
    Typography,
    Grid,
    Card,
    CardContent,
    Button,
    CardActionArea,
    CardMedia,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Helmet } from 'react-helmet'
import { useNavigate, useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4'
import appleTree from '../../utils/images/apple-orchard.jpeg'
import emeraldGreens from '../../utils/images/emeraldGreens.jpeg'
import technySingle from '../../utils/images/techny-single.jpeg'
import rowOfTechny from '../../utils/images/row-of-techny.jpeg'
import berries from '../../utils/images/berries.jpeg'
import blueberryBush from '../../utils/images/blueberry-bush.jpeg'

export default function PlantingTrees() {
    const theme = useTheme()
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: location.pathname,
            title: 'Tree Planting Services | Fruit & Screening Trees',
        })
    }, [location])

    return (
        <Grid
            container
            spacing={2}
            sx={{
                color: theme.palette.primary.textWhite,
                width: '100%',
                maxWidth: '100%',
                bgcolor: theme.palette.background.default,
                padding: 1,
            }}
        >
            <Helmet>
                <meta
                    name="description"
                    content="Professional tree planting services specializing in fruit trees, screening trees, and shrubs. Get expert care and installation from our nursery."
                />
                <meta
                    name="title"
                    content="Tree Planting Services | Fruit & Screening Trees"
                />
                <meta
                    property="og:image"
                    content="https://allroundcontractingllc.com/static/media/ARC.fce879f1b4533f68cf02.png"
                />
                <meta
                    property="og:title"
                    content="Tree Planting Services | Fruit & Screening Trees"
                />
                <meta
                    property="og:description"
                    content="Expert tree planting services for fruit trees, screening trees, and shrubs. Schedule your installation today!"
                />
                <meta
                    property="og:url"
                    content="https://allroundcontractingllc.com"
                />
                <meta property="og:type" content="website" />
                <title>Tree Planting Services | Fruit & Screening Trees</title>
                <link
                    rel="canonical"
                    href="https://allroundcontractingllc.com/tree-planting"
                />
            </Helmet>
            <Grid item xs={12}>
                <Box textAlign={'center'} padding={3}>
                    <Typography
                        variant="h1"
                        sx={{ fontSize: '2.5rem', fontWeight: 'bold' }}
                    >
                        Professional Tree & Shrub Planting Services
                    </Typography>
                    <Typography
                        variant="h2"
                        sx={{ fontSize: '1.5rem', paddingTop: 2 }}
                    >
                        Apple Trees, Fruit Shrubs, Arborvitae & More – Grown in
                        Our Nursery
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Card>
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            alt="Apple Tree"
                            height="140"
                            image={appleTree}
                            title="Apple Tree"
                        />
                        <CardContent>
                            <Typography variant="h4">
                                Fruit Tree Planting
                            </Typography>
                            <Typography variant="body1" paddingTop={1}>
                                We specialize in planting apple, pear, and
                                cherry trees that thrive in our cold climate.
                                Enjoy homegrown fruit for years to come!
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Card>
                    <CardActionArea>
                        <Box
                            display={'flex'}
                            justifyContent={'center'}
                            flexDirection={'row'}
                        >
                            <CardMedia
                                component="img"
                                alt="Blueberry Bush"
                                height="140"
                                //zoom out image
                                sx={{ objectFit: 'contain' }}
                                image={blueberryBush}
                                title="Blueberry Bush"
                            />
                            <CardMedia
                                component="img"
                                alt="Berries Harvested From Bushes"
                                height="140"
                                //zoom out image
                                sx={{ objectFit: 'contain' }}
                                image={berries}
                                title="Berry Harvest"
                            />
                        </Box>
                        <CardContent>
                            <Typography variant="h4">
                                Berry & Small Fruit Shrubs
                            </Typography>
                            <Typography variant="body1" paddingTop={1}>
                                From blueberries to raspberries, we offer expert
                                planting services to establish healthy fruiting
                                shrubs in your yard.
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Card>
                    <CardActionArea>
                        <Box
                            display={'flex'}
                            justifyContent={'center'}
                            flexDirection={'row'}
                        >
                            <CardMedia
                                component="img"
                                alt="Emerald Green Arborvitae"
                                height="140"
                                //zoom out image
                                sx={{ objectFit: 'contain' }}
                                image={emeraldGreens}
                                title="Emerald Green Arborvitae"
                            />
                            <CardMedia
                                component="img"
                                alt="Row Of Techny Arborvitae"
                                height="140"
                                //zoom out image
                                sx={{ objectFit: 'contain' }}
                                image={rowOfTechny}
                                title="Row Of Techny Arborvitae"
                            />
                            <CardMedia
                                component="img"
                                alt="Single Techny Arborvitae"
                                height="140"
                                //zoom out image
                                sx={{ objectFit: 'contain' }}
                                image={technySingle}
                                title="Single Techny Arborvitae"
                            />
                        </Box>
                        <CardContent>
                            <Typography variant="h4">
                                Screening Trees & Shrubs
                            </Typography>
                            <Typography variant="body1" paddingTop={1}>
                                Enhance your privacy with fast-growing
                                arborvitae and other dense shrubs,
                                professionally installed for maximum impact.
                                Arborvitae, Boxwood, Hydrangea, and more!
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Box textAlign={'center'} padding={3}>
                    <Typography variant="h2">Why Choose Us?</Typography>
                    <Typography variant="body1" paddingTop={2}>
                        Our trees and shrubs are grown in our own nursery,
                        ensuring healthy, well-adapted plants. We also handle
                        the entire process from site selection to professional
                        installation, at your request. Our team has the
                        expertise to ensure your new trees and shrubs thrive no
                        matter the season. Give us a call to get started!
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box textAlign={'center'} padding={3}>
                    <Typography variant="h2">Our Process</Typography>
                    <Typography variant="body1" paddingTop={2}>
                        Our team will work with you to select the perfect trees
                        and shrubs for your property. We’ll then handle all
                        aspects of installation, including site preparation,
                        planting, and mulching. We take care of everything, so
                        you can enjoy your new plants without the hassle.
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box textAlign={'center'} padding={3}>
                    <Typography variant="h2">Where do we plant?</Typography>
                    <Typography variant="body1" paddingTop={2}>
                        For small landscaping jobs, our home base is located in
                        Inver Grove Heights, Minnesota. We serve the surrounding
                        areas of South St. Paul, Woodbury, and Cottage Grove and
                        are happy to travel to your location for larger
                        projects. Don't see your city listed? Give us a call to
                        see if we can help! Want to install trees and shrubs
                        yourself? We offer delivery services to the Twin Cities
                        metro area or free pickup at our nursery in Inver Grove
                        Heights.
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box textAlign={'center'} padding={3}>
                    <Typography variant="h2">Post-Planting Care</Typography>
                    <Typography variant="body1" paddingTop={2}>
                        After installation, proper care is essential. We provide
                        detailed guidance on watering, fertilization, and
                        long-term maintenance to ensure your trees and shrubs
                        thrive. Our team is always available to answer questions
                        and provide support. We’re here to help you enjoy your
                        new plants for years to come!
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box textAlign={'center'} padding={3}>
                    <Typography variant="h2">
                        Schedule Your Tree Planting Today!
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ marginTop: 2 }}
                        onClick={() => navigate('/contactUs')}
                    >
                        Request a Quote
                    </Button>
                </Box>
            </Grid>
        </Grid>
    )
}
