//A home page for the website. It will be the landing page for customers coming
//to my landscaping site. It should account for the header in spacing, and
//there should be a captivating flow to the page with additional details

//about the services we provide, some photos and mroe details about the
//company. There should be a link to the customer dashboard

import React, { useEffect } from 'react'
import { Typography, Grid } from '@mui/material'
import './home.css'
import { Box, Button, MobileStepper } from '@mui/material'
import ReactGA from 'react-ga4'
import { autoPlay } from 'react-swipeable-views-utils'
import SwipeableViews from 'react-swipeable-views'
import {
    AcUnit,
    ArrowRight,
    Forest,
    Grass,
    KeyboardArrowLeft,
    KeyboardArrowRight,
} from '@mui/icons-material'

import { useTheme } from '@mui/material/styles'

import julie from '../../utils/images/julie.jpeg'
import jon from '../../utils/images/jon.jpeg'
import sheryl from '../../utils/images/sheryl.jpeg'
import { Helmet } from 'react-helmet'
import IGHDaysLogo from '../../utils/images/IGHDays.webp'
import NorthPoleInstall from '../../utils/images/northpolearborvitae.jpg'
import technyInstall from '../../utils/images/technyinstall.jpeg'
import snowShovel from '../../utils/images/generic_snow_removal/IMG_5870.jpeg'
import landscapingTruck from '../../utils/images/newtruck.jpeg'

export default function LandingPage() {
    const theme = useTheme()
    const [activeStep, setActiveStep] = React.useState(0)
    const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

    const images = [
        {
            label: 'Very easy to communicate with and completed the work on time.  I would hire again.',
            author: 'Aaron M',
            location: 'Apple Valley, MN',
            imgPath: snowShovel,
        },
        {
            label: '[All Round Contracting] did a fantastic job!',
            author: 'Kevin M.',
            location: 'Minneapolis, MN',
            imgPath: NorthPoleInstall,
        },
        {
            label: 'I was searching for a privacy screen plant and came across the Techny Arborvitae that [All Round Contracting] offered. [They were] very responsive, answered my questions, arrived promptly and installed 18 trees in less than 2 hours on a Sunday morning. [They] traveled an hour to my home to deliver and install. Very nice [company] to work with and cleaned up the area and even went above and beyond to remove a bush for me. Thank you for the great service, highly recommend [them] for your landscaping needs.',
            author: 'Kristin L.',
            location: 'South West Metro Twin Cities, MN',
            imgPath: technyInstall,
        },
        {
            label: '[All Round Contracting] is very responsive and a great communicator.  [They are] also very knowledgeable and a great problem solver!   The yard looks amazing!',
            author: 'Julie H.',
            location: 'Apple Valley, MN',
            imgPath: julie,
        },
        {
            label: '[All Round Contracting] does great work! The yard always looks beautiful after [they] comes by!',
            author: 'Jon I.',
            location: 'Burnsville, MN',
            imgPath: jon,
        },
        {
            label: 'Absolutely amazing work. Reasonably priced professional and did all cleanup after. I can’t say enough!',
            author: 'Sheryl W.',
            location: 'Apple Valley, MN',
            imgPath: sheryl,
        },
    ]

    const maxSteps = images.length

    const isMobile = window.innerWidth < 600 ? true : false

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    const handleStepChange = (step) => {
        setActiveStep(step)
    }

    //useEffect to send ReactGA pageview
    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: window.location.pathname,
            title: 'Home',
        })
    })

    return (
        <Grid container>
            <Helmet>
                <meta
                    name="description"
                    content="All Round Contracting LLC - Landscaping and Snow Removal in the Inver Grove Heights Area"
                />
                <meta
                    name="title"
                    content="Home - All Round Contracting LLC"
                ></meta>
                <meta
                    property="og:image"
                    content="https://allroundcontractingllc.com/static/media/ARC.fce879f1b4533f68cf02.png"
                />
                <meta
                    property="og:title"
                    content="Home - All Round Contracting LLC"
                />
                <meta
                    property="og:description"
                    content="All Round Contracting LLC - Landscaping and Snow Removal in the Inver Grove Heights Area"
                />
                <meta
                    property="og:url"
                    content="https://allroundcontractingllc.com"
                />
                <meta property="og:type" content="website" />
                <title>Home - All Round Contracting LLC</title>

                <link
                    rel="canonical"
                    href="https://allroundcontractingllc.com"
                />
            </Helmet>

            <Box
                padding={4}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'center'}
                alignItems={'center'}
                width={'100%'}
                height={isMobile ? '100vh' : `calc(100vh - 64px)`}
            >
                <Grid container height={'100%'}>
                    <Grid
                        item
                        xs={12}
                        md={7}
                        p={2}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'left'}
                    >
                        <Typography
                            variant="h1"
                            fontSize={isMobile ? '3.0rem' : '5rem'}
                            paddingBottom={2}
                            color={theme.palette.primary.textWhite}
                            fontWeight={theme.font.fontWeight.bold}
                        >
                            Expert Landscaping & Reliable Snow Removal
                        </Typography>
                        <Typography
                            variant="h1"
                            color={theme.palette.primary.textWhite}
                            fontSize={isMobile ? '1.6rem' : '2rem'}
                            paddingBottom={8}
                            fontWeight={theme.font.fontWeight.light}
                        >
                            From {''}
                            <span
                                style={{
                                    color: theme.palette.primary.main,
                                    fontWeight: theme.font.fontWeight.title,
                                }}
                            >
                                tree installation{' '}
                            </span>
                            to{' '}
                            <span
                                style={{
                                    color: theme.palette.primary.main,
                                    fontWeight: theme.font.fontWeight.title,
                                }}
                            >
                                snow clearing{' '}
                            </span>
                            we've got you covered all year 'round
                            <span
                                style={{
                                    //outline the text with a white border

                                    fontWeight: theme.font.fontWeight.bold,
                                    color: theme.palette.primary.main,
                                }}
                            ></span>
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={5}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'left'}
                    >
                        <Box
                            padding={2}
                            paddingBottom={4}
                            display={'flex'}
                            flexDirection={'column'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            width={'100%'}
                        >
                            <img
                                //add a border radius to the image
                                style={{ borderRadius: '5%' }}
                                width={'100%'}
                                src={landscapingTruck}
                                alt="Landscaping Truck"
                            />
                        </Box>
                        <Box
                            display={'flex'}
                            flexDirection={'row'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            width={'100%'}
                        >
                            <Button
                                variant="contained"
                                size="large"
                                href="/contactUs"
                                sx={{
                                    backgroundColor:
                                        theme.palette.primary.primary,
                                    color: theme.palette.primary.textBlack,
                                    //make the right side of the button rounded
                                    borderTopRightRadius: 50,
                                    borderBottomRightRadius: 50,
                                }}
                            >
                                Start Now
                                <ArrowRight />
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Grid item xs={12}>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    width={'100%'}
                    backgroundColor={theme.palette.primary.textWhite}
                >
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        width={'100%'}
                        paddingTop={4}
                        paddingBottom={4}
                        paddingLeft={2}
                        paddingRight={2}
                    >
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                md={4}
                                display={'flex'}
                                flexDirection={'column'}
                                justifyContent={'center'}
                                alignItems={'center'}
                            >
                                <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                >
                                    <Grass />
                                    <Typography
                                        variant="h6"
                                        component="div"
                                        textAlign={'center'}
                                        padding={1}
                                        sx={{ flexGrow: 1 }}
                                        color={theme.palette.primary.textBlack}
                                    >
                                        Plants
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        component="div"
                                        textAlign={'center'}
                                        padding={1}
                                        sx={{ flexGrow: 1 }}
                                        color={theme.palette.primary.textBlack}
                                    >
                                        Shopping for plants is stressful, but it
                                        doesn't have to be. We grow, sell,
                                        deliver and install a wide variety of
                                        plants, trees, and shrubs with
                                        comprehensive knowledge to help you
                                        create the perfect landscape in no time.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={4}
                                display={'flex'}
                                flexDirection={'column'}
                                justifyContent={'center'}
                                alignItems={'center'}
                            >
                                <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                >
                                    <Forest />
                                    <Typography
                                        variant="h6"
                                        component="div"
                                        textAlign={'center'}
                                        padding={1}
                                        sx={{ flexGrow: 1 }}
                                        color={theme.palette.primary.textBlack}
                                    >
                                        Planting
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        component="div"
                                        textAlign={'center'}
                                        padding={1}
                                        sx={{ flexGrow: 1 }}
                                        color={theme.palette.primary.textBlack}
                                    >
                                        The hardest part of planting new trees
                                        is putting them in the ground. Don't
                                        break your back! We can help you plant
                                        your new plants, trees, and shrubs to
                                        ensure they thrive!
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={4}
                                display={'flex'}
                                flexDirection={'column'}
                                justifyContent={'center'}
                                alignItems={'center'}
                            >
                                <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                >
                                    <AcUnit />
                                    <Typography
                                        variant="h6"
                                        component="div"
                                        textAlign={'center'}
                                        padding={1}
                                        sx={{ flexGrow: 1 }}
                                        color={theme.palette.primary.textBlack}
                                    >
                                        Snow Removal
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        component="div"
                                        textAlign={'center'}
                                        padding={1}
                                        sx={{ flexGrow: 1 }}
                                        color={theme.palette.primary.textBlack}
                                    >
                                        The last thing anyone wants to do on a
                                        cold and snowy winter morning is head
                                        outside to shovel. Let us handle the
                                        heavy snow so you can stay warm and
                                        cozy. We handle residential and
                                        commercial snow removal.
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box color={theme.palette.primary.textWhite} width={'100%'}>
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        padding={2}
                    >
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                md={4}
                                display={'flex'}
                                flexDirection={'row'}
                                justifyContent={'center'}
                                alignItems={'center'}
                            >
                                <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                >
                                    <Typography textAlign={'center'}>
                                        {images[activeStep].label}
                                    </Typography>

                                    <Typography
                                        textAlign={'center'}
                                        fontWeight={'bold'}
                                        fontStyle={'italic'}
                                        paddingTop={'1vh'}
                                    >
                                        {images[activeStep].author}
                                    </Typography>
                                    <Typography
                                        textAlign={'center'}
                                        fontStyle={'italic'}
                                    >
                                        {images[activeStep].location}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={8}
                                display={'flex'}
                                flexDirection={'column'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                padding={2}
                            >
                                <Box sx={{ maxWidth: '90%', flexGrow: 1 }}>
                                    <AutoPlaySwipeableViews
                                        axis={
                                            theme.direction === 'rtl'
                                                ? 'x-reverse'
                                                : 'x'
                                        }
                                        index={activeStep}
                                        interval={8000}
                                        animateTransitions={true}
                                        onChangeIndex={handleStepChange}
                                        enableMouseEvents
                                    >
                                        {images.map((step, index) => (
                                            <div key={step.label}>
                                                {Math.abs(activeStep - index) <=
                                                2 ? (
                                                    <Box
                                                        borderRadius={2}
                                                        sx={{
                                                            //if mobile height is 650, if desktop height is 500
                                                            height:
                                                                window.innerWidth <
                                                                600
                                                                    ? '255px'
                                                                    : '500px',

                                                            display: 'flex',
                                                            flexDirection:
                                                                'column',
                                                            justifyContent:
                                                                'center',
                                                            alignItems:
                                                                'center',
                                                            overflow: 'hidden',
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <img
                                                            width={'auto'}
                                                            height={'100%'}
                                                            loading="eager"
                                                            title={
                                                                step?.label ||
                                                                'Testimonial'
                                                            }
                                                            src={step.imgPath}
                                                            alt={step.label}
                                                        />
                                                    </Box>
                                                ) : null}
                                            </div>
                                        ))}
                                    </AutoPlaySwipeableViews>
                                </Box>

                                <MobileStepper
                                    steps={maxSteps}
                                    position="static"
                                    activeStep={activeStep}
                                    nextButton={
                                        <Button
                                            size="small"
                                            onClick={handleNext}
                                            disabled={
                                                activeStep === maxSteps - 1
                                            }
                                        >
                                            Next
                                            {theme.direction === 'rtl' ? (
                                                <KeyboardArrowLeft />
                                            ) : (
                                                <KeyboardArrowRight />
                                            )}
                                        </Button>
                                    }
                                    backButton={
                                        <Button
                                            size="small"
                                            onClick={handleBack}
                                            disabled={activeStep === 0}
                                        >
                                            {theme.direction === 'rtl' ? (
                                                <KeyboardArrowRight />
                                            ) : (
                                                <KeyboardArrowLeft />
                                            )}
                                            Back
                                        </Button>
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    width={'100%'}
                    color={theme.palette.primary.textBlack}
                    backgroundColor={theme.palette.primary.textWhite}
                    padding={2}
                >
                    <Typography
                        variant="h4"
                        component="div"
                        textAlign={'center'}
                        padding={2}
                        sx={{ flexGrow: 1, fontWeight: 'bold' }}
                    >
                        Proud Supporter of Our Community
                    </Typography>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        width={'100%'}
                    >
                        <img
                            src={IGHDaysLogo}
                            alt="IGH Days Logo"
                            height={'100px'}
                        />
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}
